<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
useHead({
  meta: [
    {
      name: "naver-site-verification",
      content: "34cd1ea19ca8bac2d92258046fde352eef4ffa3c",
    },
  ],
});
</script>
