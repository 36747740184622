import { default as ceo_45messagerJRV12IJMeMeta } from "/vercel/path0/pages/about/ceo-message.vue?macro=true";
import { default as overviewBd1MLatY29Meta } from "/vercel/path0/pages/about/overview.vue?macro=true";
import { default as way_45comeXM7gLA925aMeta } from "/vercel/path0/pages/about/way-come.vue?macro=true";
import { default as admin4FCB2UHG0ZMeta } from "/vercel/path0/pages/admin.vue?macro=true";
import { default as _91id_93CVur4qt1TkMeta } from "/vercel/path0/pages/cs/[announcement]/[id].vue?macro=true";
import { default as createlUCJqV3TcGMeta } from "/vercel/path0/pages/cs/[announcement]/create.vue?macro=true";
import { default as _91id_93YUTY13pqS4Meta } from "/vercel/path0/pages/cs/[announcement]/edit/[id].vue?macro=true";
import { default as indexwsOSeAL5VoMeta } from "/vercel/path0/pages/cs/[announcement]/index.vue?macro=true";
import { default as contactzUMM3IrHH3Meta } from "/vercel/path0/pages/cs/contact.vue?macro=true";
import { default as job_45intro1vAUlCumvyMeta } from "/vercel/path0/pages/hr/job-intro.vue?macro=true";
import { default as processpZua9u1LKBMeta } from "/vercel/path0/pages/hr/process.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as catalogpe3yOp537CMeta } from "/vercel/path0/pages/pr/catalog.vue?macro=true";
import { default as pressxPwEnufZZjMeta } from "/vercel/path0/pages/pr/press.vue?macro=true";
import { default as product3PaMVqdhk9Meta } from "/vercel/path0/pages/product.vue?macro=true";
export default [
  {
    name: ceo_45messagerJRV12IJMeMeta?.name ?? "about-ceo-message",
    path: ceo_45messagerJRV12IJMeMeta?.path ?? "/about/ceo-message",
    meta: ceo_45messagerJRV12IJMeMeta || {},
    alias: ceo_45messagerJRV12IJMeMeta?.alias || [],
    redirect: ceo_45messagerJRV12IJMeMeta?.redirect,
    component: () => import("/vercel/path0/pages/about/ceo-message.vue").then(m => m.default || m)
  },
  {
    name: overviewBd1MLatY29Meta?.name ?? "about-overview",
    path: overviewBd1MLatY29Meta?.path ?? "/about/overview",
    meta: overviewBd1MLatY29Meta || {},
    alias: overviewBd1MLatY29Meta?.alias || [],
    redirect: overviewBd1MLatY29Meta?.redirect,
    component: () => import("/vercel/path0/pages/about/overview.vue").then(m => m.default || m)
  },
  {
    name: way_45comeXM7gLA925aMeta?.name ?? "about-way-come",
    path: way_45comeXM7gLA925aMeta?.path ?? "/about/way-come",
    meta: way_45comeXM7gLA925aMeta || {},
    alias: way_45comeXM7gLA925aMeta?.alias || [],
    redirect: way_45comeXM7gLA925aMeta?.redirect,
    component: () => import("/vercel/path0/pages/about/way-come.vue").then(m => m.default || m)
  },
  {
    name: admin4FCB2UHG0ZMeta?.name ?? "admin",
    path: admin4FCB2UHG0ZMeta?.path ?? "/admin",
    meta: admin4FCB2UHG0ZMeta || {},
    alias: admin4FCB2UHG0ZMeta?.alias || [],
    redirect: admin4FCB2UHG0ZMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CVur4qt1TkMeta?.name ?? "cs-announcement-id",
    path: _91id_93CVur4qt1TkMeta?.path ?? "/cs/:announcement()/:id()",
    meta: _91id_93CVur4qt1TkMeta || {},
    alias: _91id_93CVur4qt1TkMeta?.alias || [],
    redirect: _91id_93CVur4qt1TkMeta?.redirect,
    component: () => import("/vercel/path0/pages/cs/[announcement]/[id].vue").then(m => m.default || m)
  },
  {
    name: createlUCJqV3TcGMeta?.name ?? "cs-announcement-create",
    path: createlUCJqV3TcGMeta?.path ?? "/cs/:announcement()/create",
    meta: createlUCJqV3TcGMeta || {},
    alias: createlUCJqV3TcGMeta?.alias || [],
    redirect: createlUCJqV3TcGMeta?.redirect,
    component: () => import("/vercel/path0/pages/cs/[announcement]/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YUTY13pqS4Meta?.name ?? "cs-announcement-edit-id",
    path: _91id_93YUTY13pqS4Meta?.path ?? "/cs/:announcement()/edit/:id()",
    meta: _91id_93YUTY13pqS4Meta || {},
    alias: _91id_93YUTY13pqS4Meta?.alias || [],
    redirect: _91id_93YUTY13pqS4Meta?.redirect,
    component: () => import("/vercel/path0/pages/cs/[announcement]/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwsOSeAL5VoMeta?.name ?? "cs-announcement",
    path: indexwsOSeAL5VoMeta?.path ?? "/cs/:announcement()",
    meta: indexwsOSeAL5VoMeta || {},
    alias: indexwsOSeAL5VoMeta?.alias || [],
    redirect: indexwsOSeAL5VoMeta?.redirect,
    component: () => import("/vercel/path0/pages/cs/[announcement]/index.vue").then(m => m.default || m)
  },
  {
    name: contactzUMM3IrHH3Meta?.name ?? "cs-contact",
    path: contactzUMM3IrHH3Meta?.path ?? "/cs/contact",
    meta: contactzUMM3IrHH3Meta || {},
    alias: contactzUMM3IrHH3Meta?.alias || [],
    redirect: contactzUMM3IrHH3Meta?.redirect,
    component: () => import("/vercel/path0/pages/cs/contact.vue").then(m => m.default || m)
  },
  {
    name: job_45intro1vAUlCumvyMeta?.name ?? "hr-job-intro",
    path: job_45intro1vAUlCumvyMeta?.path ?? "/hr/job-intro",
    meta: job_45intro1vAUlCumvyMeta || {},
    alias: job_45intro1vAUlCumvyMeta?.alias || [],
    redirect: job_45intro1vAUlCumvyMeta?.redirect,
    component: () => import("/vercel/path0/pages/hr/job-intro.vue").then(m => m.default || m)
  },
  {
    name: processpZua9u1LKBMeta?.name ?? "hr-process",
    path: processpZua9u1LKBMeta?.path ?? "/hr/process",
    meta: processpZua9u1LKBMeta || {},
    alias: processpZua9u1LKBMeta?.alias || [],
    redirect: processpZua9u1LKBMeta?.redirect,
    component: () => import("/vercel/path0/pages/hr/process.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: catalogpe3yOp537CMeta?.name ?? "pr-catalog",
    path: catalogpe3yOp537CMeta?.path ?? "/pr/catalog",
    meta: catalogpe3yOp537CMeta || {},
    alias: catalogpe3yOp537CMeta?.alias || [],
    redirect: catalogpe3yOp537CMeta?.redirect,
    component: () => import("/vercel/path0/pages/pr/catalog.vue").then(m => m.default || m)
  },
  {
    name: pressxPwEnufZZjMeta?.name ?? "pr-press",
    path: pressxPwEnufZZjMeta?.path ?? "/pr/press",
    meta: pressxPwEnufZZjMeta || {},
    alias: pressxPwEnufZZjMeta?.alias || [],
    redirect: pressxPwEnufZZjMeta?.redirect,
    component: () => import("/vercel/path0/pages/pr/press.vue").then(m => m.default || m)
  },
  {
    name: product3PaMVqdhk9Meta?.name ?? "product",
    path: product3PaMVqdhk9Meta?.path ?? "/product",
    meta: product3PaMVqdhk9Meta || {},
    alias: product3PaMVqdhk9Meta?.alias || [],
    redirect: product3PaMVqdhk9Meta?.redirect,
    component: () => import("/vercel/path0/pages/product.vue").then(m => m.default || m)
  }
]